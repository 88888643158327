<!--
 * @Author: Lmi
 * @Description: 设施设备定期检查维护记录表新增
-->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm0" class="el-form-wrap">
        <ykc-form-item label="电站名称" prop="stationId">
          <ykc-dropdown
            placeholder="请选择电站"
            :clearable="false"
            :data="stationData"
            v-model="ruleForm.stationId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="检查时间" prop="checkDate">
          <ykc-date-picker
            v-model="ruleForm.checkDate"
            :format="'yyyy-MM-dd'"
            :valueFormat="'yyyy-MM-dd'"
            :type="'datetime'"
            placeholder="请选择检查时间"
            :onlyHour="true"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="维护保养时间" prop="maintenanceDate">
          <ykc-date-picker
            v-model="ruleForm.maintenanceDate"
            :format="'yyyy-MM-dd'"
            :valueFormat="'yyyy-MM-dd'"
            :type="'datetime'"
            placeholder="请选择维护保养时间"
            :onlyHour="true"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="维护保养记录" prop="maintenanceRecord">
          <ykc-input
            placeholder="请输入维护保养记录"
            maxlength="500"
            v-model="ruleForm.maintenanceRecord"
            type="textarea"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="检查维护保养人" prop="maintenancePerson">
          <ykc-input
            placeholder="请输入检查维护保养人"
            maxlength="50"
            v-model="ruleForm.maintenancePerson"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="备注" prop="remark">
          <ykc-input
            placeholder="请输入备注信息"
            maxlength="200"
            v-model="ruleForm.remark"
            type="textarea"></ykc-input>
        </ykc-form-item>

        <ykc-form-item
          prop="img"
          label="附件上传"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过3M">
          <div class="flex upload-img-box">
            <ykc-upload
              ref="pictureUrls1"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs1"></ykc-upload>
            <ykc-upload
              ref="pictureUrls2"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs2"></ykc-upload>
            <ykc-upload
              ref="pictureUrls3"
              :size="3"
              @handleChange="uploadImgs1"
              @handleRemove="handleRemoveImgs3"></ykc-upload>
          </div>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { omManagementApi, barrierPage, uploadUtils } from '@/service/apis';

  export default {
    name: 'AddOrEdit',
    data() {
      return {
        stationData: [], // 电站数据
        ruleForm: {
          stationId: '',
          maintenancePerson: '', // 检查维护保养人
          checkDate: '', // 检查时间
          maintenanceDate: '', // 维护保养时间
          maintenanceRecord: '', // 维护保养记录
          remark: '',
          filePath: [],
        },
        rules: {
          stationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          checkDate: [{ required: true, trigger: 'change', message: '请选择检查时间' }],
          maintenanceDate: [{ required: true, trigger: 'change', message: '请选择维护保养时间' }],
          maintenancePerson: [{ required: true, message: '请输入检查维护保养人', trigger: 'blur' }],
          maintenanceRecord: [{ required: true, message: '请输入维护保养记录', trigger: 'blur' }],
        },
      };
    },
    created() {
      this.getStationData();
    },
    computed: {
      formData() {
        const formData = {
          stationId: this.ruleForm.stationId,
          maintenancePerson: this.ruleForm.maintenancePerson,
          checkDate: this.ruleForm.checkDate,
          maintenanceDate: this.ruleForm.maintenanceDate,
          maintenanceRecord: this.ruleForm.maintenanceRecord,
          remark: this.ruleForm.remark,
          filePath: this.ruleForm.filePath,
        };

        return formData;
      },
    },
    methods: {
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },
      /**
       * 切換整改情況
       */
      changeRectificationSituation(e) {
        console.log(JSON.stringify(e));
      },
      /**
       * 切換復查情況
       */
      changeReviewSituation(e) {
        console.log(JSON.stringify(e));
      },
      /**
       * @desc  图片上传
       */
      uploadImgs1(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.filePath.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      /**
       * @desc 图片移除
       * */
      handleRemoveImgs1(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(0, 1);
      },
      handleRemoveImgs2(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(1, 1);
      },
      handleRemoveImgs3(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.filePath.splice(2, 1);
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm()
            .then(() => {
              omManagementApi
                .equipmentMaintenanceAdd(this.formData)
                .then(res => {
                  console.log('res++++新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * @desc 校验当前表格是否通过
       * */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              if (this.ruleForm.latitude !== null && this.ruleForm.longitude !== null) {
                resolve();
              } else {
                this.$message({
                  message: '请先点击地图定位按钮获取当前坐标!',
                  type: 'warning',
                });
              }
            } else {
              reject();
            }
          });
        });
      },
      /**
       * @desc 校验全部表格是否通过
       * */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('err++++', err);
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    > div {
      margin-right: 20px;
    }
  }
</style>
