<!--
 * @Author: Lmi
 * @Description: 运维管理 现场人员 设备维护列表
-->
<template>
  <scroll-layout class="equipment-maintenance-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button
            v-rbac="'maintain:personnel:equipmentMaintenance:add'"
            type="plain"
            @click="add">
            新增
          </ykc-button>
          <ykc-button
            v-rbac="'maintain:personnel:equipmentMaintenance:export'"
            type="plain"
            @click="doExportFile">
            导出
          </ykc-button>
        </div>
      </template>
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <ykc-drawer
      title="设施设备定期检查维护记录表新增"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit ref="addOrEdit"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>
<script>
  import { offlineExport, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import AddOrEdit from './AddOrEdit.vue';
  import { omManagementApi } from '@/service/apis';

  export default {
    name: 'equipmentMaintenanceList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        stationId: null,
        showDrawer: false,
        maxIndex: 1,
        stepsIndex: 0,
        searchParams: {
          stationName: '',
          checkDateStart: '',
          checkDateEnd: '',
          maintenancePerson: '',
        },
        tableTitle: '检查维护列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '电站名称', prop: 'stationName', minWidth: '200px' },
          { label: '检查时间', prop: 'checkDate', minWidth: '200px' },
          { label: '检查维护保养人', prop: 'maintenancePerson', minWidth: '200px' },
          { label: '维护保养记录', prop: 'maintenanceRecord', minWidth: '200px' },
          { label: '维护保养时间', prop: 'maintenanceDate', minWidth: '200px' },
          { label: '备注', prop: 'remark', minWidth: '200px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcDatePicker',
            key: 'checkDate',
            label: '请选择检查时间',
          },
          {
            comName: 'YkcInput',
            key: 'maintenancePerson',
            label: '检查维护保养人',
            placeholder: '请输入检查维护保养人',
          },
        ];
      },
    },
    created() {
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            text: '详情',
            enabled: () => code('maintain:personnel:equipmentMaintenance:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/operationManagement/scenePersonnel/equipmentMaintenance/Detail',
                query: { id: row.id },
              });
            },
          },
        ];
      },
      /**
       * 新增
       * */
      add() {
        this.stepsIndex = 0;
        this.showDrawer = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'equipment_maintenance',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        Object.assign(this.searchParams, searchData);

        if (searchData.checkDate && searchData.checkDate.length > 0) {
          [this.searchParams.checkDateStart, this.searchParams.checkDateEnd] = searchData.checkDate;
        }
        this.pageInfo.current = 1;
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
          },
        });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        omManagementApi
          .equipmentMaintenanceQuery(reqParams)
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
